<template>
  <div>
    <!--搜索框-->
    <van-cell-group>
      <van-field
        label="律师名称"
        v-model="lawyerName"
        placeholder="律师名称"
        :border="false"
        bind:change="onChange"
      />
      <van-field
        label="用户名"
        v-model="nickName"
        placeholder="用户名"
        :border="false"
        bind:change="onChange"
      />
      <van-field
        label="手机号"
        v-model="phone"
        placeholder="手机号"
        :border="false"
        bind:change="onChange"
      />
      <van-field
        label="所在城市"
        v-model="city"
        placeholder="所在城市"
        :border="false"
        bind:change="onChange"
      />
      <!--客服组件-->
      <customer @cusValue="chooseCus" />
      <!--查询时间组件-->
      <queryDate @startResult="startResult" @endResult="endResult" />
      <van-button type="info" size="large" @click="onload">查询</van-button>
    </van-cell-group>
    <!--数据展示-->
    <div>
      <el-table
        :data="
          tableData.slice(
            (currentPage - 1) * pageCount,
            currentPage * pageCount
          )
        "
        max-height="700"
        border
        style="width: 100%"
      >
        <el-table-column prop="lawyerName" label="律师名称" width="100" />
        <el-table-column prop="nickName" label="用户名" width="100" />
        <el-table-column prop="phone" label="手机号" width="100" />
        <el-table-column prop="create_time" label="注册时间" width="100" />
        <el-table-column
          prop="member_end_time"
          label="会员到期时间"
          width="100"
        />
        <el-table-column
          prop="last_login_time"
          label="最后登录时间"
          width="100"
        />
        <el-table-column prop="city" label="所在城市" width="100" />
        <el-table-column prop="status" label="用户状态" width="100" />
        <el-table-column prop="duty" label="用户角色" width="100" />
        <el-table-column prop="isAuth" label="认证情况" width="100" />
        <el-table-column fixed="right" label="操作" width="100" prop="status">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="visitLog(scope.row)"
              >反馈记录</el-button
            >
            <el-button type="text" size="small" @click="register(scope.row)"
              >律师认证</el-button
            >
            <el-button type="text" size="small" @click="callPhone(scope.row)"
              >拨打电话</el-button
            >
            <el-button type="text" size="small" @click="udpatePwd(scope.row)"
              >修改密码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="footer"
        small
        layout="prev, pager, next"
        :total="total"
        :page-count="pageCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <van-dialog
      use-slot
      title="修改密码"
      v-model="showUpdatePwd"
      show-cancel-button
      @close="onClose"
      @confirm="commitPwd"
    >
      <van-field
        label="新密码"
        v-model="newPwd"
        placeholder="新密码"
        :border="false"
        bind:change="onChange"
      />
      <van-field
        label="确认密码"
        v-model="confirmPwd"
        placeholder="确认密码"
        :border="false"
        @blur="confirmInput"
      />
    </van-dialog>
  </div>
</template>

<script>
import customer from "../../compents/customer.vue";
export default {
  components: { customer },
  data() {
    return {
      tableData: [], //初始化数据
      pageCount: 10, //展示条数
      currentPage: 1, //当前页
      total: 0, //总条数
      userId: "", //用户id
      lawyerName: "", //律师名称
      nickName: "", //用户名
      phone: "", //手机号
      city: "", //所在城市
      isAuth: "", //认证情况
      customer: "", //客服
      status: "", //状态
      startTime: "", //开始时间
      endTime: "", //结束时间
      showUpdatePwd: false, //修改密码弹窗
      newPwd: "", //新密码
      confirmPwd: "", //旧密码
    };
  },
  methods: {
    //反馈记录新增
    visitLog(val) {
      window.location.href = `${this.$base}/fission/page/visitLog?id=${val.id}`;
    },

    //密码确认
    confirmInput() {
      if (this.newPwd !== this.confirmPwd) {
        this.$toast("两次密码输入不对应");
        return;
      }
    },
    //弹窗数据提交
    commitPwd() {
      if (this.newPwd !== this.confirmPwd) {
        this.$toast("两次密码输入不对应");
      } else {
        this.$axios
          .post(`${this.$base}/farbun/user/systemPswd?pswd=${this.newPwd}`, {
            id: this.userId,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$toast("操作成功");
              this.showUpdatePwd = false;
            }
          });
      }
    },
    //弹窗关闭
    onClose() {
      this.showUpdatePwd = false;
    },
    //修改密码
    udpatePwd(val) {
      this.userId = val.id;
      this.showUpdatePwd = true;
    },
    //律师认证
    register(val) {
      this.$router.push("/register?phone=" + val.phone);
    },
    //拨打电话
    callPhone(val) {
      window.location.href = "tel:" + val.phone;
    },
    //客服组件赋值
    chooseCus(val) {
      this.customer = val;
    },
    //初始化
    onload() {
      this.total = 0;
      this.tableData = [];
      this.$axios
        .post(`${this.$base}/fission/user/queryClient`, {
          userId: this.userId,
          lawyerName: this.lawyerName,
          nickName: this.nickName,
          city: this.city,
          isAuth: this.isAuth,
          customer: this.customer,
          status: this.status,
          startTime: this.startTime,
          endTime: this.endTime,
          phone: this.phone,
        })
        .then((res) => {
          if (res) {
            res.forEach((item) => {
              item.status = this.userStatus(item.status);
              item.isAuth = this.isAuthStatus(item.isAuth);
              this.tableData.push(item);
            });
            this.total = this.tableData.length;
          }
        });
    },

    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    //认证情况
    isAuthStatus(val) {
      if (val == 2) {
        return "已认证";
      } else {
        return "未认证";
      }
    },
    //用户账号状态
    userStatus(val) {
      switch (val) {
        case 1:
          return "有效";
        case 0:
          return "禁止登陆";
        case -1:
          return "账户被删除";
        default:
          return "";
      }
    },
    //开始时间
    startResult(val) {
      this.startTime = val;
    },
    //结束时间
    endResult(val) {
      this.endTime = val;
    },
  },
  mounted() {
    this.onload();
  },
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
